import React, { useState, useEffect, useRef } from 'react';

const Cards = ({ items, selectedCategory }) => {
  const [isPlaying, setIsPlaying] = useState(false); // Track whether the audio is playing
  const [currentTime, setCurrentTime] = useState(0); // Current time of the audio
  const [duration, setDuration] = useState(0); // Duration of the audio
  const [activeSound, setActiveSound] = useState(null); // Track which sound is active
  const [audioSrc, setAudioSrc] = useState(""); // Audio file URL
  const audioRef = useRef(new Audio()); // Keep a single audio reference
  const [isLooping, setIsLooping] = useState(false); // Loop state
  const [listeners, setListeners] = useState({}); // Track active listeners per sound

  // Convert seconds to minutes:seconds format
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`; // Format seconds as two digits
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.pause(); // Pause the audio when selectedCategory changes
    }
  }, [selectedCategory]);

  // Handle card click to set the active sound and play the audio
  const handleCardClick = (item) => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the currently playing audio
      setIsPlaying(false); // Update the state to reflect that audio is no longer playing
    }

    setActiveSound(item); // Set the clicked card as the active sound
    setCurrentTime(0); // Reset time to start from the beginning

    if (audioSrc !== item.audio) {
      setAudioSrc(item.audio); // Update the audio source with the new clicked item's audio file URL
      setCurrentTime(0); // Reset time to start from the beginning
    }

    // Play the new audio
    if (audioRef.current) {
      audioRef.current.src = item.audio; // Set the new audio source
      audioRef.current.play(); // Play the new audio immediately
      setIsPlaying(true); // Set playing state to true
    }

    // Optionally, increase active listeners count
    setListeners((prevListeners) => {
      const updatedListeners = { ...prevListeners };
      updatedListeners[item.id] = (updatedListeners[item.id] || 0) + 1;
      return updatedListeners;
    });
  };

  // Handle play/pause toggle
  const handlePlayPause = () => {
    if (audioRef.current) { // Ensure the audio element is available
      if (isPlaying) {
        audioRef.current.pause(); // Pause the audio
      } else {
        audioRef.current.play(); // Play the audio
      }

      setIsPlaying((prevState) => !prevState); // Toggle the play/pause state
    }
  };

  // Handle skipping forward by 10 seconds
  const handleNext = () => {
    const newTime = Math.min(currentTime + 10, duration); // Skip forward 10 seconds, but not beyond duration
    setCurrentTime(newTime); // Update current time
    if (audioRef.current) {
      audioRef.current.currentTime = newTime; // Update the audio's current time
    }
  };

  // Handle skipping backward by 10 seconds
  const handlePrevious = () => {
    const newTime = Math.max(currentTime - 10, 0); // Skip backward 10 seconds, but not below 0
    setCurrentTime(newTime); // Update current time
    if (audioRef.current) {
      audioRef.current.currentTime = newTime; // Update the audio's current time
    }
  };

  // Handle changing the current playback position
  const handleSeek = (e) => {
    const newTime = e.target.value;
    setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  // Handle the loop toggle
  const handleLoop = () => {
    const newLoopState = !isLooping; // Toggle loop state
    setIsLooping(newLoopState); // Update the loop state
    if (audioRef.current) {
      audioRef.current.loop = newLoopState; // Set the loop attribute on the audio
    }
  };


  // Update current time based on the audio's playback position
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        setDuration(audioRef.current.duration); // Set the duration once the audio metadata is loaded
      };

      // Update current time
      const timeUpdateInterval = setInterval(() => {
        setCurrentTime(audioRef.current.currentTime);
      }, 100); // Update every 100ms

      return () => clearInterval(timeUpdateInterval); // Clean up the interval on unmount
    }
  }, [audioRef.current]);

  // Update state when audio is paused or played
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onplay = () => setIsPlaying(true);
      audioRef.current.onpause = () => setIsPlaying(false);
    }
  }, [audioRef.current]);

  return (
    // That component for display the cards and audio players
    <div>
      
      <div className="cards-grid">
        {items.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() => handleCardClick(item)} // Click event to show the music bar
          >
             <img src={item.image} alt={item.title} loading="lazy" /> 
            <h5>{item.title}</h5>
            <p>{item.description}</p>
          </div>
        ))}
      </div>

      {/* Music Control Bar */}
      {activeSound && (
        <div className="music-control-bar">
          <div className="left">
            {/* Display song title and description */}
            <img src={activeSound.image} alt={activeSound.title} loading="lazy" />
            <div className="leftsmalltextdiv">
              <p>{activeSound.description}</p>
            </div>
          </div>
          <div className="middle">
         
              <img src="./images/backward.svg" alt="Previous" width="24" height="24" onClick={handlePrevious} className="control-btn"/>
 

            {/* Play & Pause button */}
            <button onClick={handlePlayPause} className="control-btn">
              {isPlaying ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path d="M6 6h4v12H6zM14 6h4v12h-4z" fill="currentColor" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path d="M8 5v14l11-7z" fill="currentColor" />
                </svg>
              )}
            </button>

           
              <img src="./images/forward.svg" alt="Next" width="24" height="24" onClick={handleNext} className="control-btn"/>
           

            {/* Loop button */}
            <button onClick={handleLoop} className={`control-btn ${isLooping ? 'active' : ''}`}>
              <svg className="loop-button" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="22px" height="22px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16" className="iconify" data-icon="bi:repeat" data-width="22px" data-height="22px" data-inline="false" style={{ transform: 'rotate(360deg)' }}><path fill="currentColor" d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777a.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777a.5.5 0 0 1 .225-.67Z" /></svg>
            </button>

          </div>
          <div className="right">
            {/* Display time in minutes:seconds */}
            <span>
              {formatTime(currentTime)} / {formatTime(duration)}
            </span>
            <input
              type="range"
              min="0"
              max={duration}
              value={currentTime}
              onChange={handleSeek}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Cards;
