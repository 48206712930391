import './Categories.css';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import API_BASE_URL from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'reactjs-popup/dist/index.css';

const Cards = () => {

    const [popupAdd, setPopupAdd] = useState(false);
    const [popupEdit, setPopupEdit] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // Track whether the audio is playing
    const [cards, setCards] = useState([]);
    const [itemdetail, setItemdetail] = useState();
    const [imagePreview, setImagePreview] = useState(); // State for image preview
    const [imageFile, setImageFile] = useState(null); // State for the actual image file
    const [audioFile, setAudioFile] = useState(null); // State for the audio file
    const [audioPreview, setAudioPreview] = useState(null);
    const [title, setTitle] = useState('');
    const [categorie_id, setCategorie_id] = useState();
    const [categories, setCategories] = useState([]);

    const [card_id, setCard_id] = useState();
    const fetchCategories = async () => {
        try {
            const response = await fetch('https://idvxu157q2.execute-api.us-east-1.amazonaws.com/prod/fetch-categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
            });

            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }

            const data = await response.json(); // Await the JSON response
            setCategories(JSON.parse(data.body)); // Parse the body to set the users
            console.log(JSON.parse(data.body)); // Log the users
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchCards = async () => {
        try {
            // that endpoint for fecth cards via secure apigateway.
            const response = await fetch('https://47tn5imz4c.execute-api.us-east-1.amazonaws.com/prod/fetch-cards', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ action: 'fetch' }), // Specify the 'fetch' action
            });

            if (!response.ok) {
                throw new Error('Failed to fetch items');
            }

            const data = await response.json(); // Await the JSON response
            setCards(JSON.parse(data.body)); // Parse the body to set the users
            console.log(JSON.parse(data.body)); // Log the users
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };


    useEffect(() => {
        fetchCategories();
        fetchCards();
    }, []);



    const handlePopupAdd = () => {
        setPopupAdd(true);
        setTitle('');
        setImagePreview();
        setAudioPreview();

    }

    const handlePopupEdit = (ele) => {
        setPopupEdit(true);
        setItemdetail(ele);
        setImagePreview(ele.image);
        setTitle(ele.description);
        setCard_id(ele.id);
        setAudioPreview(ele.audio);
        setAudioFile(ele.audio);
        setImageFile(ele.image);
        setCategorie_id(ele.categorie_id);
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Set the preview to the selected file
            };
            reader.readAsDataURL(file); // Read the file as Data URL (base64)
        }
    };

    const handleAudioChange = (e) => {
        setAudioPreview();
        setAudioFile();
        const file = e.target.files[0];  // Get the selected file
        if (file) {
            setAudioFile(file);  // Save the audio file
            const audioURL = URL.createObjectURL(file);  // Create a URL for the audio file
            setAudioPreview(audioURL);  // Update the state with the new audio URL
        }
    };





    const handleTitleChange = (e) => {
        setTitle(e.target.value); // Set the title from the textarea
    };

    const handleCategorieChange = (e) => {
        setCategorie_id(e.target.value); // Set the title from the textarea
    };

    const getContentTypeFromExtension = (filePath) => {
        const extension = filePath.split('.').pop().toLowerCase();

        // Map file extensions to Content-Type values
        switch (extension) {
            case 'jpg':
            case 'jpeg':
                return 'image/jpeg';
            case 'png':
                return 'image/png';
            case 'gif':
                return 'image/gif';
            case 'bmp':
                return 'image/bmp';
            case 'webp':
                return 'image/webp';
            case 'mp3':
                return 'audio/mpeg';
            case 'wav':
                return 'audio/wav';
            case 'ogg':
                return 'audio/ogg';
            default:
                return 'application/octet-stream';  // Default for unknown types
        }
    };


    const handleSubmitEdit = async (e) => {
        e.preventDefault();
         // Validate inputs
        if (!title || !imageFile || !audioFile) {
            console.error('Title, image, and audio are required.');
            return;
        }
    
        // Function to convert file to base64
        const readAsBase64 = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    reject(new Error('Parameter is not a Blob/File'));
                    return;
                }
    
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 without metadata
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        };
    
        try {
            let imageData = null;
            let audioData = null;
    
            // Handle image file (either File object or URL)
            if (typeof imageFile === 'string' && imageFile.startsWith('http')) {
                const contentType = getContentTypeFromExtension(imageFile);
                imageData = {
                    name: imageFile.split('/').pop(),
                    content: imageFile,
                    contentType: contentType
                };
            } else if (imageFile instanceof File) {
                const imageBase64 = await readAsBase64(imageFile);
                const imageContentType = imageFile.type;
                imageData = {
                    name: imageFile.name,
                    content: imageBase64,
                    contentType: imageContentType
                };
            } else {
                console.error('Invalid image file');
                return;
            }
    
            // Handle audio file (either File object or URL)
            if (typeof audioFile === 'string' && audioFile.startsWith('http')) {
                const contentType = getContentTypeFromExtension(audioFile);
                audioData = {
                    name: audioFile.split('/').pop(),
                    content: audioFile,
                    contentType: contentType
                };
            } else if (audioFile instanceof File) {
                const audioBase64 = await readAsBase64(audioFile);
                const audioContentType = audioFile.type;
                audioData = {
                    name: audioFile.name,
                    content: audioBase64,
                    contentType: audioContentType,
                };
            } else {
                console.error('Invalid audio file');
                return;
            }
    
            // Send data to API
            const response = await fetch('https://47tn5imz4c.execute-api.us-east-1.amazonaws.com/prod/fetch-cards', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'update',
                    data: {
                        id:card_id,
                        title: categories.find((ele) => ele.id == categorie_id)?.title  ,
                        description: title  ,
                        imageFile: imageData,
                        audioFile: audioData,
                        categorieId: categorie_id  ,
                    },
                }),
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Failed to add card');
            }
    
            const data = await response.json();
            console.log('Card added:', data);
    
            // Reset form
            setTitle('');
            setImageFile(null);
            setAudioFile(null);
            setImagePreview('');
            setPopupEdit(false);
    
            // Refresh the categories list
            await fetchCards();
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };
    






    const handleSubmitAdd = async (e) => {
        e.preventDefault();

        // Check if title and imageFile are not empty
        if (!title || !imageFile) {
            console.error('Title and image are required.');
            return;
        }

        // Helper function to read a file as base64
        const readAsBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result.split(',')[1]); // Extract base64 without metadata
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            });
        };

        try {
            // Convert image and audio files to base64
            const imageBase64 = await readAsBase64(imageFile);
            const imageContentType = imageFile.type;

            let audioBase64 = null;
            let audioContentType = null;

            if (audioFile) {
                audioBase64 = await readAsBase64(audioFile);
                audioContentType = audioFile.type; // Get audio MIME type
            }

            const response = await fetch('https://47tn5imz4c.execute-api.us-east-1.amazonaws.com/prod/fetch-cards', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'store',
                    data: {
                        title: categories.find((ele) => ele.id == categorie_id)?.title || 'Unknown',
                        description: title || 'No description',
                        imageFile: {
                            name: imageFile.name,
                            content: imageBase64,
                            contentType: imageContentType,
                        },
                        audioFile: audioFile
                            ? {
                                name: audioFile.name,
                                content: audioBase64,
                                contentType: audioContentType, // Include contentType for audio
                            }
                            : null,
                        categorieId: categorie_id || null,
                    },
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText); // Log response text for errors
                throw new Error('Failed to add card');
            }

            const data = await response.json();
            console.log('Card added:', data);

            // Reset form
            setTitle('');
            setImageFile(null);
            setAudioFile(null); // Reset audio file
            setImagePreview('');

            // Close the popup
            setPopupAdd(false);

            // Refresh the categories list
            await fetchCards();
        } catch (error) {
            console.error('Error adding category:', error);
        }
    };



    const handleSubmitRemove = async (id) => {
        /* eslint-disable no-restricted-globals */
        if (confirm("Are you sure you want to delete that?")) {
            try {
                // Make the DELETE request
                const response = await fetch('https://47tn5imz4c.execute-api.us-east-1.amazonaws.com/prod/fetch-cards', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'delete',
                        data: { id },
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to delete the card');
                }

                const data = await response.json();
                console.log('Card deleted:', data);

                await fetchCards(); // Refresh the list
            } catch (error) {
                console.error('Error deleting card:', error);
            }
        }
        /* eslint-enable no-restricted-globals */
    };
    return (
        // this component for displaying and storing the cards.
        <section className="ftco-section st_content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-4">
                        <h2 className="heading-section">Cards</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 lists-container">
                        <button className="button-21" role="button" onClick={() => handlePopupAdd()}>Add <FontAwesomeIcon icon={faPlus} className='' /></button>

                        <div className="table-wrap">
                            <table className="table">
                                <thead className="thead-primary">
                                    <tr>

                                        <th>Image</th>
                                        <th>Audio</th>
                                        <th>Title</th>
                                        <th>Categorie</th>
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cards.map((ele) => {
                                            return (
                                                <tr className="alert" role="alert">
                                                    <td>
                                                        <div className="img" style={{ backgroundImage: `url(${ele.image})` }} />
                                                    </td>
                                                    <td>
                                                        <div className="email" style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                                                            <audio controls>
                                                                <source src={ele.audio} type="audio/mp3" />
                                                                Your browser does not support the audio element.
                                                            </audio>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="email">
                                                            <span>{ele.description}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="email">
                                                            <span>{ele.title}</span>
                                                        </div>
                                                    </td>
                                                    <td className='btn-containere'>

                                                        <FontAwesomeIcon icon={faEdit} className='' onClick={() => { handlePopupEdit(ele) }} />
                                                        <FontAwesomeIcon icon={faClose} className='' onClick={() => handleSubmitRemove(ele.id)} />

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                popupAdd ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupAdd(false)}>
                                &times;
                            </button>

                            <h2>Add new card</h2>
                            <form onSubmit={handleSubmitAdd}>
                                <div class="form-group">
                                    <label for="image">Image</label>
                                    <input type="file" name="image" id="image" onChange={handleImageChange} />
                                    {imagePreview &&
                                        <img src={imagePreview} alt="Image Preview" width={50} onChange={handleImageChange} />
                                    }
                                </div>
                                <div class="form-group">
                                    <label for="audio">Audio</label>
                                    <input type="file" name="audio" id="audio" onChange={handleAudioChange} />
                                    {audioPreview &&
                                        <div>
                                            <audio controls>
                                                <source src={audioPreview} type="audio/mp3" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    }
                                </div>
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <textarea name="title" id="title" cols="30" rows="3" value={title} onChange={handleTitleChange}></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="categoprie_id">Categorie</label>
                                    <select name="categoprie_id" id="categoprie_id" onChange={handleCategorieChange}>
                                        <option selected disabled>Choose a categorie</option>
                                        {
                                            categories.map((ele) => {
                                                return (
                                                    <option value={ele.id}>{ele.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div class="form-action">
                                    <button type="submit">Add</button>
                                </div>
                            </form>


                        </div>
                    </div>
                ) : null
            }


            {
                popupEdit ? (
                    <div className="popup">
                        <div className="popup-inner">
                            <button className="close-btn" onClick={() => setPopupEdit(false)}>
                                &times;
                            </button>

                            <h2>Edit card</h2>
                            <form onSubmit={handleSubmitEdit}>
                                <div class="form-group">
                                    <label for="image">Image</label>
                                    <input type="file" name="image" id="image" onChange={handleImageChange} />
                                    {imagePreview &&
                                        <img src={imagePreview} alt="Image Preview" width={50} onChange={handleImageChange} />
                                    }
                                </div>
                                <div class="form-group">
                                    <label for="audio">Audio</label>
                                    <input type="file" name="audio" id="audio" onChange={handleAudioChange} />
                                    {audioPreview &&
                                        <div>
                                            <audio controls>
                                                <source src={audioPreview} type="audio/mp3" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    }
                                </div>
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <textarea name="title" id="title" cols="30" rows="3" value={title} onChange={handleTitleChange}></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="categoprie_id">Categorie</label>
                                    <select name="categoprie_id" id="categoprie_id" onChange={handleCategorieChange}>
                                        <option selected disabled>Choose a categorie</option>
                                        {
                                            categories.map((ele) => {
                                                return (
                                                    <option value={ele.id} selected={ele.title == itemdetail.title}>{ele.title}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div class="form-action">
                                    <button type="submit">Update</button>
                                </div>
                            </form>


                        </div>
                    </div>
                ) : null
            }
        </section>

    );
};

export default Cards;
