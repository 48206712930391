import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Header from './Header';
import Cards from './Cards';
import './Home.css';
import { fetchItems, fetchCategories } from './reducers';
import { setSelectedCategory, setSearchQuery, setFilteredItems } from './reducers';

const Home = () => {
  const dispatch = useDispatch();
  const { categories, items, filteredItems, selectedCategory, searchQuery, loading, error } = useSelector((state) => state.data);
  const [isopen, setIsopen] = useState(false)
  // Fetch categories and items only once on mount (will use cached data if available)
  useEffect(() => {
    dispatch(fetchCategories()); // Fetch categories only if not already fetched
    dispatch(fetchItems()); // Fetch items only if not already fetched
  }, [dispatch]);

  // Handle filtering of items based on selectedCategory and searchQuery
  useEffect(() => {
    const filtered = items.filter(item => {
      const matchesCategory = selectedCategory === 'All' || item.title === selectedCategory;
      const matchesSearch = item.description && item.description.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesCategory && matchesSearch;
    });

    dispatch(setFilteredItems(filtered)); // Update filtered items in Redux store
  }, [dispatch, items, selectedCategory, searchQuery]);

  // Memoized callback to handle category selection
  const handleCategorySelect = useCallback((category) => {
    dispatch(setSelectedCategory(category)); // Update the selected category
  }, [dispatch]);

  // Memoized callback to handle search query change
  const handleSearchChange = useCallback((e) => {
    dispatch(setSearchQuery(e.target.value)); // Update search query
  }, [dispatch]);

  return (
    // that component for home page.
    <div className="app">
      <Sidebar categories={categories} onCategorySelect={handleCategorySelect} selectedCategory={selectedCategory} setIsopen={setIsopen} isopen={isopen} />
      <div className="main-content">
        <Header selectedCategory={selectedCategory} searchQuery={searchQuery} handleSearchChange={handleSearchChange} setIsopen={setIsopen} isopen={isopen} />
        {loading ? <div className="loading-spinner"></div> : (
          Array.isArray(filteredItems) && filteredItems.length > 0
            ? <Cards items={filteredItems} selectedCategory={selectedCategory} />
            : <div className='loading-spinner'></div>
        )}
      </div>
    </div>
  );
};

export default Home;
