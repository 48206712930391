import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

import ReCAPTCHA from 'react-google-recaptcha'
import './Login.css'
const LoginForm = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Added loading state
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [disabledsignup, setDisabledsingup] = useState(false);
    const [loginhide, setLoginhide] = useState(false);
    const [singuphide, setSignuphide] = useState(false);
    const [disabledlogin, setDisabledlogin] = useState(false);


    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    // that logic for handling the login
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!login || !password) {
            alert("All fields are required!");
            return;
        }

        if (!recaptchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'login',
                    login: login,
                    password: password,
                }),
            });

            if (response.status !== 200) {
                const errorText = await response.data;
                console.error('Error response:', errorText);
                throw new Error('Failed to login');
            }

            const data = await JSON.parse(response.data.body);
            console.log(data)

            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('role', data.role);

                window.location.href = data.role == 'user' ? '/' : '/AdminDashboard';

            } else {
                setError('Login failed. Please check your credentials.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred during login.');
        } finally {
            setLoading(false);
        }
    };


    // that function for handling the register
    const handleRegister = async (e) => {
        e.preventDefault();

        if (!login || !password) {
            alert("All fields are required!");
            return;
        }
        if (!recaptchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }

        setLoading(true);
        setError('');
        try {
            const response = await axios.post('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'register',
                    login: login,
                    password: password,
                    role: 'user'
                }),
            });

            if (response.status !== 200) {
                const errorText = await response.data;
                console.error('Error response:', errorText);
                throw new Error('Failed to login');
            }

            const data = await JSON.parse(response.data.body);
            console.log(data);

            if (data.message === 'User registered successfully.') {
                alert("Registration successful!");
                window.location.href = '/';

            } else {
                alert(data.message || 'Registration failed.');
            }

        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred during registration.');
        } finally {
            setLoading(false);
        }
    };


    // and that function for google authentification.
    const responseMessage = async (res) => {
        console.log(res)

        try {
            // that endpoint for calling the apigateway of Authentification lambda function it more secure with generate the  tokens to 
            // be more secure and used the aws cognito to more secure.
            const response = await axios.post('https://solkllb70e.execute-api.us-east-1.amazonaws.com/prod/authentification', {
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'googleLogin',
                    token: res.access_token
                }),
            });

            if (response.status !== 200) {
                const errorText = await response.data;
                console.error('Error response:', errorText);
                throw new Error('Failed to login');
            }

            const data = await JSON.parse(response.data.body);
            console.log(data)
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('role', data.role);
                window.location.href = data.role === 'user' ? '/' : '/AdminDashboard';
            } else {
                setError('Google login failed.');
            }
        } catch (err) {
            setError(err.message || 'An error occurred during Google login.');
        }

    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const googleLogin = useGoogleLogin({
        onSuccess: responseMessage,
        onError: errorMessage,
    });

    const handleHideSignUp = () => {
        setDisabledsingup(!disabledsignup)
        setDisabledlogin(!disabledlogin);
        setLoginhide(false)

    }

    const handleHideLogIn = () => {
        setDisabledsingup(!disabledsignup)
        setDisabledlogin(!disabledlogin);
        setSignuphide(false)

    }

    const handleloginhide = () => {
        setLoginhide(!loginhide)

    }
    const handlesigninhide = () => {
        setSignuphide(!singuphide)

    }
    return (
        <>


            <div role="dialog" className="kXzxyX" style={{ display: !disabledsignup ? "block" : "none" }}>
                <div id="SM_ROOT_COMP607" className="CT3fEw">
                    <h1 id="signUpHeadline_SM_ROOT_COMP607" className="pbgYig">Sign Up</h1>
                    <div className="uildOz UUKRoc KLY9cP" >
                        <div className="HY3iVh">
                            <span className="pTT3vK">Already a member?</span>
                            <button className="FhIHQq" type="button" onClick={handleHideSignUp} >Log In</button>
                        </div>
                        <div id="SM_ROOT_COMP483" className="FR20Zg" style={{ display: !singuphide ? "block" : "none" }}>
                            <div className="TFrXvd gZuoTz">
                                <div data-testid="socialAuth" className="Xk41h0">
                                    <div className="Xk41h0">
                                        <div className="u05eqo NMFZzm">
                                            <div className="FubTgk" id="googleSM_ROOT_COMP483" aria-disabled="false">
                                                <button aria-disabled="false" className="uDW_Qe wixui-button PlZyDq" onClick={() => googleLogin()}>
                                                    <span className="l7_2fn wixui-button__label">Sign up with Google</span>
                                                </button>
                                            </div>
                                        </div>
                                        <p className="ao1Z7i" />
                                    </div>
                                </div>
                                <div className="_0kuZ0"><span>or</span></div>
                                <div data-testid="switchToEmailLink" className="ka7snc">
                                    <div data-testid="switchToEmailLink" className="FubTgk" id="switchToEmailLink_SM_ROOT_COMP483" aria-disabled="false">
                                        <button aria-disabled="false" className="uDW_Qe wixui-button PlZyDq" onClick={handlesigninhide}>
                                            <span className="l7_2fn wixui-button__label">Sign up with
                                                Email</span>
                                        </button></div>
                                </div>

                            </div>
                        </div>

                        <form className="dAE1gt" onSubmit={handleRegister} style={{ display: singuphide ? "block" : "none" }}>
                            <div className="Ve6Lmg">
                                <div className="qhAIF3">
                                    <div id="emailInput_SM_ROOT_COMP607" className="gV3oeT">
                                        <div id="input_emailInput_SM_ROOT_COMP607" className="MpKiNN wixui-text-input qzvPmW lPl_oN">
                                            <label htmlFor="input_input_emailInput_SM_ROOT_COMP607" className="wPeA6j wixui-text-input__label">Email</label>
                                            <div className="pUnTVX"><input id="input_input_emailInput_SM_ROOT_COMP607" className="KvoMHf has-custom-focus wixui-text-input__input" type="email" required value={login}
                                                onChange={(e) => setLogin(e.target.value)} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jv2c1Y">
                                    <div id="passwordInput_SM_ROOT_COMP607" className="gV3oeT">
                                        <div id="input_passwordInput_SM_ROOT_COMP607" className="MpKiNN wixui-text-input qzvPmW lPl_oN">
                                            <label htmlFor="input_input_passwordInput_SM_ROOT_COMP607" className="wPeA6j wixui-text-input__label">Password</label>
                                            <div className="pUnTVX">
                                                <input id="input_input_passwordInput_SM_ROOT_COMP607" className="KvoMHf has-custom-focus wixui-text-input__input" type="password" required value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="RolTZP">
                                <div id="signInCaptcha" className="Captcha3940957316__root Captcha3940957316--loaded wixui-captcha">
                                    <ReCAPTCHA sitekey={'6LeJPZcqAAAAAKIKntP4rHdNUkHYy0wSxka71wDD'} onChange={handleRecaptchaChange} />
                                </div>
                            </div>
                            {error && <div className="error-message" style={{ color: "red" }}>{error}</div>}

                            <div data-testid="submit" className="ujgVG2">
                                <div className="FubTgk FubTgk2" id="okButton_SM_ROOT_COMP607">
                                    <button className="uDW_Qe wixui-button PlZyDq" disabled={loading}>
                                        <span className="l7_2fn l7_2fn2 wixui-button__label">{loading ? 'Signing Up...' : 'Sign Up'}</span>
                                    </button>
                                </div>
                            </div>


                            <div >
                                <div className="_YL3aD G5z2h8"><span>or sign up with</span></div>
                                <div className="Xk41h0" onClick={() => googleLogin()} style={{ cursor: "pointer" }}>
                                    <svg className="z8EdsK NMFZzm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-testid="googlenew-small">
                                        <path d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z" style={{ fill: 'rgb(251, 187, 0)' }} />
                                        <path d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z" style={{ fill: 'rgb(81, 142, 248)' }} />
                                        <path d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z" style={{ fill: 'rgb(40, 180, 70)' }} />
                                        <path d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z" style={{ fill: 'rgb(241, 67, 54)' }} />
                                    </svg>
                                    <p className="ao1Z7i" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <div role="dialog" className="kXzxyX" style={{ display: disabledlogin ? "block" : "none" }}>
                <div id="SM_ROOT_COMP607" className="CT3fEw">
                    <h1 id="signUpHeadline_SM_ROOT_COMP607" className="pbgYig">Log In</h1>
                    <div className="uildOz UUKRoc KLY9cP">
                        <div className="HY3iVh">
                            <span className="pTT3vK">New to this site?</span>
                            <button className="FhIHQq" type="button" onClick={handleHideLogIn} >Sign Up</button>
                        </div>

                        <div id="SM_ROOT_COMP483" className="FR20Zg" style={{ display: !loginhide ? "block" : "none" }}>
                            <div className="TFrXvd gZuoTz">
                                <div data-testid="socialAuth" className="Xk41h0">
                                    <div className="Xk41h0">
                                        <div className="u05eqo NMFZzm">
                                            <div className="FubTgk" id="googleSM_ROOT_COMP483" aria-disabled="false">
                                                <button aria-disabled="false" className="uDW_Qe wixui-button PlZyDq" onClick={() => googleLogin()}>
                                                    <span className="l7_2fn wixui-button__label">Log in with Google</span>
                                                </button>
                                            </div>
                                        </div>
                                        <p className="ao1Z7i" />
                                    </div>
                                </div>
                                <div className="_0kuZ0"><span>or</span></div>
                                <div data-testid="switchToEmailLink" className="ka7snc">
                                    <div data-testid="switchToEmailLink" className="FubTgk" id="switchToEmailLink_SM_ROOT_COMP483" aria-disabled="false">
                                        <button aria-disabled="false" className="uDW_Qe wixui-button PlZyDq" onClick={handleloginhide}>
                                            <span className="l7_2fn wixui-button__label">Log in with
                                                Email</span>
                                        </button></div>
                                </div>

                            </div>
                        </div>


                        <form className="dAE1gt" onSubmit={handleLogin} style={{ display: loginhide ? "block" : "none" }}>
                            <div className="Ve6Lmg">
                                <div className="qhAIF3">
                                    <div id="emailInput_SM_ROOT_COMP607" className="gV3oeT">
                                        <div id="input_emailInput_SM_ROOT_COMP607" className="MpKiNN wixui-text-input qzvPmW lPl_oN">
                                            <label htmlFor="input_input_emailInput_SM_ROOT_COMP607" className="wPeA6j wixui-text-input__label">Email</label>
                                            <div className="pUnTVX"><input id="input_input_emailInput_SM_ROOT_COMP607" className="KvoMHf has-custom-focus wixui-text-input__input" type="email" required value={login}
                                                onChange={(e) => setLogin(e.target.value)} /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="jv2c1Y">
                                    <div id="passwordInput_SM_ROOT_COMP607" className="gV3oeT">
                                        <div id="input_passwordInput_SM_ROOT_COMP607" className="MpKiNN wixui-text-input qzvPmW lPl_oN">
                                            <label htmlFor="input_input_passwordInput_SM_ROOT_COMP607" className="wPeA6j wixui-text-input__label">Password</label>
                                            <div className="pUnTVX">
                                                <input id="input_input_passwordInput_SM_ROOT_COMP607" className="KvoMHf has-custom-focus wixui-text-input__input" type="password" required value={password}
                                                    onChange={(e) => setPassword(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="RolTZP">
                                <div id="signInCaptcha" className="Captcha3940957316__root Captcha3940957316--loaded wixui-captcha">
                                    <ReCAPTCHA sitekey={'6LeJPZcqAAAAAKIKntP4rHdNUkHYy0wSxka71wDD'} onChange={handleRecaptchaChange} />
                                </div>
                            </div>
                            {error && <div className="error-message" style={{ color: "red" }}>{error}</div>}
                            <div data-testid="submit" className="ujgVG2">
                                <div className="FubTgk FubTgk2" id="okButton_SM_ROOT_COMP607">
                                    <button className="uDW_Qe wixui-button PlZyDq" disabled={loading}>
                                        <span className="l7_2fn l7_2fn2 wixui-button__label">{loading ? 'Logging In...' : 'Log In'}</span>
                                    </button>
                                </div>
                            </div>

                            <div >
                                <div className="_YL3aD G5z2h8"><span>or log in with</span></div>
                                <div className="Xk41h0" onClick={() => googleLogin()} style={{ cursor: "pointer" }}>
                                    <svg className="z8EdsK NMFZzm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-testid="googlenew-small">
                                        <path d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z" style={{ fill: 'rgb(251, 187, 0)' }} />
                                        <path d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z" style={{ fill: 'rgb(81, 142, 248)' }} />
                                        <path d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z" style={{ fill: 'rgb(40, 180, 70)' }} />
                                        <path d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z" style={{ fill: 'rgb(241, 67, 54)' }} />
                                    </svg>
                                    <p className="ao1Z7i" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    );
};

export default LoginForm;
